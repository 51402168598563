import Chart from 'chart.js'

Chart.defaults.global.defaultFontColor = getComputedStyle(document.documentElement).getPropertyValue('--gray')
Chart.defaults.global.defaultFontFamily = getComputedStyle(document.documentElement).getPropertyValue('--font-family-sans-serif')
Chart.defaults.global.defaultFontSize = 16

const colors = {
  blue: getComputedStyle(document.documentElement).getPropertyValue('--blue'),
  indigo: getComputedStyle(document.documentElement).getPropertyValue('--indigo'),
  purple: getComputedStyle(document.documentElement).getPropertyValue('--purple'),
  pink: getComputedStyle(document.documentElement).getPropertyValue('--pink'),
  red: getComputedStyle(document.documentElement).getPropertyValue('--red'),
  orange: getComputedStyle(document.documentElement).getPropertyValue('--orange'),
  yellow: getComputedStyle(document.documentElement).getPropertyValue('--yellow'),
  green: getComputedStyle(document.documentElement).getPropertyValue('--green'),
  teal: getComputedStyle(document.documentElement).getPropertyValue('--teal'),
  cyan: getComputedStyle(document.documentElement).getPropertyValue('--cyan'),
  gray: getComputedStyle(document.documentElement).getPropertyValue('--gray')
}

const colorsIndexed = []
// eslint-disable-next-line guard-for-in
for (const color in colors) {
  colorsIndexed.push(colors[color])
}
const colorLength = colorsIndexed.length

// Define some usefull formater to use for tick and tooltip
window.chartFormatterHour = function (value) {
  return `${new Intl.NumberFormat('fr-FR').format(value)} H`
}

window.chartFormatterMoney = function (value) {
  return new Intl.NumberFormat('fr-FR', {
    currency: 'EUR',
    style: 'currency'
  }).format(value)
}

window.chartFormatterSec2dur = function (value) {
  let hours = Math.floor(value / 3600) || 0
  let mins = Math.floor(value / 60 % 60) || 0
  let secs = Math.floor(value % 60) || 0

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (mins < 10) {
    mins = `0${mins}`
  }
  if (secs < 10) {
    secs = `0${secs}`
  }

  return `${hours}:${mins}:${secs}`
}

window.chartFormatterMs2dur = function (value) {
  return window.chartFormatterSec2dur(value / 1000)
}

window.chartFormatterHour2dur = function (value) {
  return window.chartFormatterSec2dur(value * 3600)
}


const chartsCanvas = document.querySelectorAll('canvas.chart')
chartsCanvas.forEach((el) => {
  let chartConfig = {}

  if (typeof el.dataset.chart !== 'undefined') {
    chartConfig = JSON.parse(el.dataset.chart) || {}
  } else if (typeof el.dataset.chartVar !== 'undefined') {
    chartConfig = eval(el.dataset.chartVar)
  }

  if (typeof chartConfig.type !== 'undefined') {
    switch (chartConfig.type) { // eslint-disable-line default-case
      case 'doughnut':
      case 'pie':
      case 'polarArea':
        chartConfig.data.datasets.forEach((dataset) => {
          if (typeof dataset.backgroundColor === 'undefined') {
            dataset.backgroundColor = dataset.data.map((data, dataIndex) => {
              const colorIndex = dataIndex % colorLength
              return colorsIndexed[colorIndex]
            })
          }
        })
        break
    }
  }

  const chart = new Chart(el, chartConfig)

  el.chartJs = chart
})
