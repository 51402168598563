import $ from 'jquery'

$(document).ready(() => {
  $('.hamburger[data-toggle="collapse"]').each((i, element) => {
    element = $(element)
    const target = element.data('target')

    $(target).on('show.bs.collapse', () => {
      element.addClass('is-active')
    })

    $(target).on('hide.bs.collapse', () => {
      element.removeClass('is-active')
    })
  })
})
