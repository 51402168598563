import $ from 'jquery'

$(document).ready(() => {
  const $timeEntriesWrapper = $('#time-entries-wrapper')
  const $btnWrapper = $('#time-entries-pagination-wrapper')
  if ($btnWrapper.length <= 0) {
    return
  }

  const $btn = $btnWrapper.find('button')
  if (typeof $btn.data('page') === 'undefined') {
    $btn.data('page', 1)
  }

  $btn.click((e) => {
    e.preventDefault()

    $timeEntriesWrapper.css('opacity', 0.4) // eslint-disable-line no-magic-numbers
    $btn.prop('disabled', true)

    $.get(document.location.href, {
      page: $btn.data('page') + 1
    }, (data) => {
      $timeEntriesWrapper.append(data.append)
      $btn.data('page', data.currentPage)

      if (!data.hasNextPage) {
        $btnWrapper.remove()
      }
    })
      .always(() => {
        $timeEntriesWrapper.css('opacity', 1)
        $btn.prop('disabled', false)
      })
  })
})
