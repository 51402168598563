document.addEventListener('DOMContentLoaded', () => {
  const body = document.querySelector('body')
  const loader = document.querySelector('.site-loader')

  if (!loader) {
    return
  }

  loader.addEventListener('transitionend', () => {
    loader.remove()
  })

  body.classList.remove('loading')
  loader.classList.add('hidding')
})
