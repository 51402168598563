import $ from 'jquery'

$(document).ready(() => {
  $('[data-poload]').on('shown.bs.popover', (e) => {
    const $element = $(e.target)
    const $popover = $(`#${$element.attr('aria-describedby')}`)

    $popover.addClass('ajax-popover')

    const url = $element.data('poload')

    if (url && $popover.length > 0) {
      $.get(url, (data) => {
        if (typeof data.html !== 'undefined') {
          $popover.find('.popover-body').html(data.html)
        }
      })
    }
  })
})
